import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import options from './options';
import Scrollbars from '../../components/utility/customScrollBar.js';
import Menu from '../../components/uielements/menu';
import IntlMessages from '../../components/utility/intlMessages';
import SidebarWrapper from './sidebar.style';
import appActions from '../../redux/app/actions';
import Logo from '../../components/utility/logo';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import qs from 'query-string'
import config from '../../config'
import axios from 'axios'
const APP_VERSION = config.version

const prefixs = ['lottovip', 'ruay', 'jetsada']

const itemStyle = {
    backgroundColor:'#520000',
    width:'100%',
    height:'80%',
    borderRadius:8,
    justifyContent:'center',
    textAlign:'center',
    border:'2px solid #f9d276',
    fontSize:22,
    color:'white',
    fontWeight: 'bold', 
    padding:10
}



const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed
} = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      winrate : [0,0,0,0,0]
    }
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  componentDidMount = async()=> {
    const search = qs.parse(this.props.history.location.search)
    const res = await axios.get(`${config.url}:5000/data?game=${prefixs[search.game-1]}`)
    const dataRes = res.data
    let win = [0,0,0,0,0]
    let lose = [0,0,0,0,0]
    let winrate = [0,0,0,0,0]
    dataRes.map((item) => {
      const parseData = item.split('|')
      const randoms = parseData[3].split(',')
      randoms.map((random, index) => {
        const data = [parseData[1],parseData[2],random[0],random[1]]
        const checkRes = checkFormula(data)
        if (checkRes.className === 'win') {
          win[index] += 1
        } else if (checkRes.className === 'lose') {
          lose[index] += 1
        }
        winrate[index] = Math.round(win[index] * 100 / (win[index] + lose[index]))
      })

    })
    this.setState({ winrate })
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === 'MobileView') {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ['sub2']
    };
    return map[key] || [];
  };
  getMenuItem = ({index, singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    const search = qs.parse(this.props.history.location.search)
    search.formula = key
    const {winrate} = this.state
    return (
      <Menu.Item key={key} style={{height:110}}>
        <div style={itemStyle} onClick={()=>{
            this.props.history.push({search:`?${qs.stringify(search)}`,pathname:this.props.history.location.pathname})
            window.location.reload()
        }}>
          {label}
          <p style={{fontSize:12,marginTop:-10}}>{'อัตตราการชนะ'}
          <span style={{fontSize:21,fontWeight:'bold',color:'#ffc107',marginLeft:4}}>{`${winrate[index]}%`}</span>
          </p>
        </div>
      </Menu.Item>
    );
  };
  render() {
    const { app, toggleOpenDrawer, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? 'vertical' : 'inline';
    const onMouseEnter = event => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    const submenuStyle = {
      backgroundColor: 'rgba(0,0,0,0.3)',
      color: customizedTheme.textColor
    };
    const submenuColor = {
      color: customizedTheme.textColor
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed}/>
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options.map((singleOption,index) =>
                this.getMenuItem({ index,submenuStyle, submenuColor, singleOption })
              )}
            </Menu>
            <p style={{color:'#c7daff', fontWeight: 'bold',textAlign:'center',marginBottom:20}}>{`Version ${APP_VERSION}`}</p>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
    height: state.App.height
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);


const checkFormula = (item) => {
  if (item[0] === 'xxx' && item[1] === 'xx') {
    return {
      className: 'wait',
      title: 'รอผล'
    }
  }else if (item[0].indexOf(item[2]) !== -1) {
    return {
      className: 'win',
      title: 'สูตรถูก'
    }
  } else if (item[0].indexOf(item[3]) !== -1) {
    return {
      className: 'win',
      title: 'สูตรถูก'
    }
  } else if (item[1].indexOf(item[2]) !== -1) {
    return {
      className: 'win',
      title: 'สูตรถูก'
    }
  } else if (item[1].indexOf(item[3]) !== -1) {
    return {
      className: 'win',
      title: 'สูตรถูก'
    }
  } else if (item[2] === '-') {
    return {
      className: '',
      title: 'ไม่มีสูตร'
    }
  }
  return {
    className: 'lose',
    title: 'สูตรผิด'
  }
}