const env = {}
const PROD = true
const URL = PROD?"https://api.pgslot99.link":'http://localhost'
env.url = URL
env.parseServerUrl = `${URL}:1337/parse`
env.appId = "97dd70d3-da0a-432a-9dc9-01ef5a06c6a9"
env.masterKey = "597e1c2d-21f6-40d4-8748-29a5f2f30f98"
env.javascriptKey = "bdbd0236-bc83-457d-a5b0-08a273859dbb"
env.lineURL = 'https://page.line.me/227vbgug'
env.appName = 'PGSLOT99'
env.version = "2.0.2"
export default env